import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthSelectors } from 'src/app/auth/store';
import { PartnerCompanyMenuSectionVM } from 'src/app/home/models/partner-company-menu-section.vm';
import { AppSelectors } from 'src/app/store';
import * as fromHome from './home.reducer';

export const selectHomeState = createFeatureSelector<fromHome.State>(
  fromHome.homeFeatureKey
);
export const selectPartnerCompanyMenuSectionVMs = createSelector(AuthSelectors.selectPartnerCompanyAggregates, partnerCompanyAggregates => partnerCompanyAggregates.map((agg, index) => (<PartnerCompanyMenuSectionVM>{
  id: agg.id,
  name: agg?.company?.name,
  isExpanded: index === 0
})).sort((a, b) => a.name.localeCompare(b.name)));
export const selectDriverMenuSectionHeaderTitle = createSelector(AuthSelectors.selectUserFullName, userFullName => userFullName || '');
export const selectAreas = createSelector(AppSelectors.selectAreas, areas => areas);
export const selectIsDriverMenuVisible = createSelector(AuthSelectors.selectDriver, driver => !!driver);
