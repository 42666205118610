import { createReducer } from '@ngrx/store';

export const homeFeatureKey = 'home';

/* eslint-disable  @typescript-eslint/no-empty-interface */
export interface State {
}

export const initialState: State = {
};

export const reducer = createReducer(
  initialState,
);
