import { createAction } from '@ngrx/store';

export const homeInit = createAction(
  '[Home] Init'
);

export const homeDestroy = createAction(
  '[Home] Destroy'
);

export const logoutButtonClicked = createAction(
  '[Home] Logout button clicked'
);
